<template>
  <div>
    <a
      @click="$router.back()"
      href="#"
      class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg d-flex flex-row align-items-center"
    >
      <i class="mdi mdi-chevron-left"></i>
      Kembali
    </a>
    <validation-observer ref="formKelas">
      <b-form @submit.prevent="submitFormKelas" @reset="onReset">
        <div class="card card-custom card-stretch">
          <div class="card-header border-0 py-5">
            <h3 class="card-title align-items-start flex-column">
              <span class="card-label font-weight-bolder text-dark">
                {{ this.idKelas ? "Edit" : "Buat" }} Branch
              </span>
            </h3>
            <div class="card-toolbar">
              <button
                type="submit"
                class="btn btn-success font-weight-bolder font-size-sm mr-2"
              >
                Simpan
              </button>
              <span
                @click="$router.back()"
                class="btn btn-secondary font-weight-bolder font-size-sm"
              >
                Batal</span
              >
            </div>
          </div>
          <div class="card-body pt-4">
            <b-form-group label="Region">
              <validation-provider
                #default="{ errors }"
                name="Region"
                rules="required"
              >
                <vue-select
                  :state="errors.length > 0 ? false : null"
                  :loading="isRegionLoad"
                  single
                  async
                  v-model="currentKelasForm.body.region_id"
                  :options="regions"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              id="input-group-1"
              label="Nama Branch:"
              label-for="input-1"
            >
              <validation-provider
                #default="{ errors }"
                name="Nama Branch"
                rules="required"
              >
                <b-form-input
                  :state="errors.length > 0 ? false : null"
                  id="input-1"
                  v-model="currentKelasForm.body.class_name"
                  type="text"
                  placeholder="Masukkan Nama Branch"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
        </div>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

import {
  STORE_CLASS,
  ASSIGN_CLASS,
  DETAIL_CLASS,
  UPDATE_CLASS,
} from "@/core/services/store/kelas.module";

import { GET_REGION } from "@/core/services/store/region.module";

import Swal from "sweetalert2";

import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  name: "KelasForm",
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      currentKelasForm: {
        body: {},
      },
      regions: [],
      isLoading: false,
      isRegionLoad: false,
      isCreate: true,
      editorOption: {
        // Some Quill options...
      },
      currentTeacherForm: {
        class_id: {},
        teachers_id: [],
      },
    };
  },
  mounted() {
    if (this.Id) {
      const selected = this.currentClass;

      if (selected.region_id === undefined) {
        this.$router.back();
      }

      this.currentKelasForm = {
        body: {
          ...selected,
        },
      };
    }

    this.fetchRegion();
    this.currentKelasForm.body.subject = "test";
  },
  methods: {
    submitFormKelas() {
      this.$refs.formKelas.validate().then((success) => {
        if (success) {
          this.currentKelasForm.body.region_id =
            this.currentKelasForm.body.region_id.id;
          const _data = JSON.parse(JSON.stringify(this.currentKelasForm));
          this.$store
            .dispatch(this.idKelas ? UPDATE_CLASS : STORE_CLASS, _data)
            .then((response) => {
              Swal.fire({
                title: response.message,
                text: `Berhasil ${this.idKelas ? "ubah" : "buat"} Branch`,
                icon: "success",
                confirmButtonClass: "btn btn-secondary",
                heightAuto: false,
              });
              this.$router.push(`/branch`);
              this.isLoading = false;
            })
            .catch(() => {
              this.isLoading = false;
              this.$bvToast.toast(this.errors, {
                title: `Gagal ${this.idKelas ? "Edit" : "Membuat"} Kelas`,
                variant: "danger",
                solid: true,
              });
            });
        }
      });
    },
    onReset() {
      this.currentKelasForm = {
        body: {},
      };
    },
    fetchRegion() {
      this.isRegionLoad = true;
      this.$store
        .dispatch(GET_REGION)
        .then((response) => {
          this.regions = response.data.data.map((item) => {
            return {
              label: item.region_name,
              id: item._id,
            };
          });
          // this,this.regions =
          this.isRegionLoad = false;
        })
        .catch(() => {
          this.isRegionLoad = false;
        });
    },
  },
  computed: {
    ...mapState({
      errors: (state) => state.classModule.errors,
    }),
    ...mapGetters(["currentUserList", "currentClass"]),
    idKelas() {
      return this.$route.params.idKelas;
    },
    isType() {
      return this.$route.params.type;
    },
    Id() {
      return this.$route.params.id;
    },
  },
  watch: {},
};
</script>
